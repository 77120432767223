<template>
  <img :src="placeholder_lands" :data-src="getPoster(content)" class="continueWatchPoster" alt="" :style="[{ 'border-radius': `${borderRadius}` }]" @error="actLoadError" data-lazy-load :key="content.id+ '?t=' + new Date().getTime()"/>    
</template>
  
<script>
  import { observerInterSection } from "@/modules/observerInterSection";
  
  export default {
    name: "ImageTemplate",
    props: {
        content: {
            type: Object,
        },
        borderRadius: {
          type: String,
          default: "0px"
        }
    },
    data() {
      return {
        gotPosterItem: null,
        placeholder_port: require('@/assets/placeholder-port.svg'),
        placeholder_lands: require('@/assets/placeholder-lands.svg'),
      }
    },
    mounted() {
        // this.getPoster(this.content);
        observerInterSection();
    },
    methods: {
      actLoadError (e) {
        e.target.src = placeholder_lands;
      },
      getPoster(content) {
        const filterData = content?.poster?.filter((item) => {
          if (item.postertype === "LANDSCAPE") return item;
        });

        if (filterData?.length > 0 ) {
          const finalImg = [];
          let alterFileList  = filterData[0]?.filelist;          
          for (let el of alterFileList) {
            if (el.quality === "SD") {
              this.gotPosterItem = el.filename;
              break;
            } else if (el.quality === "HD") {
              this.gotPosterItem = el.filename;
              break;
            } else {
              this.gotPosterItem = this.placeholder_lands;
              
              break;
            }
          }
          // this.gotPosterItem = this.gotPosterItem ? this.gotPosterItem : this.placeholder_lands;
        } else if(content.thumbnail){
          this.gotPosterItem = content.thumbnail;
        } else {
          this.gotPosterItem = this.placeholder_lands;
        }
        return this.gotPosterItem;
        
      },
  
    },

    components: {     
      PlayButton: () => import(/* webpackChunkName: "playButton" */ "@/components/buttons/play.vue"),
    },
  }
</script>
  
